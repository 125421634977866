import classNames from 'classnames';
import { FC } from 'react';
import styles from './index.module.scss';

const Index: FC<{ number: number }> = ({ number }) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>Word Count: </div>
      <div className={classNames(styles.count, { [styles.count0]: number === 0 })}>{number}</div>
    </div>
  );
};

export default Index;
