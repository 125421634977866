import { Section } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import { NewSection, SectionLoadingState, SectionModifiedEvent } from '@/type/advanceWriting';

export const changeSectionModifiedTag = (sections: NewSection[], path: number[], event: SectionModifiedEvent) => {
  if (path.length) {
    let currentSection: NewSection | undefined = sections[path[0]];

    for (const index of path.slice(1)) {
      if (currentSection?.subLevels) {
        currentSection = currentSection.subLevels[index];
      }
    }

    if (currentSection) {
      if (event === SectionModifiedEvent.SectionContentModified) {
        currentSection.isContentModified = true;
      }
      if (event === SectionModifiedEvent.SectionOutlineModified) {
        currentSection.isOutlineModified = true;
      }
    }
  }
};

export const removeSectionModifiedTag = (sections: NewSection[], event: SectionModifiedEvent) => {
  for (const section of sections) {
    if (event === SectionModifiedEvent.SectionContentModified) {
      section.isContentModified = false;
    }
    if (event === SectionModifiedEvent.SectionOutlineModified) {
      section.isOutlineModified = false;
    }
    if (section.subLevels?.length) {
      removeSectionModifiedTag(section.subLevels, event);
    }
  }
};

export const getSectionByPath = (sections: NewSection[], path: number[]) => {
  if (path.length) {
    let currentSection: NewSection | undefined = sections[path[0]];

    for (const index of path.slice(1)) {
      if (currentSection?.subLevels) {
        currentSection = currentSection.subLevels[index];
      }
    }
    return currentSection || null;
  }
  return null;
};

export const getSectionById = (sections: NewSection[], sectionId?: string): NewSection | null => {
  for (const section of sections) {
    if (section.sectionId === sectionId) {
      return section;
    }
    if (section.subLevels?.length) {
      const matchedSubSection = getSectionById(section.subLevels, sectionId);
      if (matchedSubSection) {
        return matchedSubSection;
      }
    }
  }
  return null;
};

export const getSectionModifiedState = (section: NewSection, includeSubsection?: boolean) => {
  let isContentModified = section.isContentModified || false;
  let isOutlineModified = section.isOutlineModified || false;
  if (section.subLevels && includeSubsection) {
    for (const subSection of section.subLevels) {
      const { isContentModified: issubSectionContentModified, isOutlineModified: isSubSectionOutlineModified } = getSectionModifiedState(subSection);
      isContentModified = isContentModified || issubSectionContentModified;
      isOutlineModified = isOutlineModified || isSubSectionOutlineModified;
    }
  }
  return { isContentModified, isOutlineModified };
};

export const getSectionLoadingState = (section: NewSection): SectionLoadingState => {
  let isSubSectionGeneratingOutline = false;
  let isSubSectionGeneratingContent = false;
  let isSubSectionGeneratingSummary = false;
  if (section.subLevels?.length) {
    for (const subSection of section.subLevels) {
      const result = getSectionLoadingState(subSection);
      isSubSectionGeneratingOutline = isSubSectionGeneratingOutline || result.isGeneratingOutline;
      isSubSectionGeneratingContent = isSubSectionGeneratingContent || result.isGeneratingContent;
      isSubSectionGeneratingSummary = isSubSectionGeneratingSummary || result.isGeneratingSummary;
    }
  }
  const { isGeneratingContent = false, isGeneratingOutline = false, isGeneratingSummary = false } = section;
  return {
    isGeneratingOutline,
    isGeneratingContent,
    isGeneratingSummary,
    isSubSectionGeneratingContent,
    isSubSectionGeneratingOutline,
    isSubSectionGeneratingSummary,
  };
};

// 打平sections
export const flattenSections = (sections: Section[], level = 0): NewSection[] => {
  let result: NewSection[] = [];
  for (const section of sections) {
    result.push({ ...section, level });
    if (section.subLevels && section.subLevels.length > 0) {
      result = result.concat(flattenSections(section.subLevels, level + 1));
    }
  }

  return result;
};

// 改变section里的selectIndex
export const changeSectionValue = (sections: NewSection[], sectionId: Section['sectionId'], position: 1 | -1) => {
  for (const section of sections) {
    if (section.sectionId === sectionId && section.sectionContents?.length) {
      if (typeof section.selectIndex !== 'number') {
        // 默认选中数组第一个(后端返回数据是按照版本倒序)
        section.selectIndex = 0;
      }
      section.selectIndex += position;
    }
    if (section.subLevels) {
      changeSectionValue(section.subLevels, sectionId, position);
    }
  }
};

// 获取缺失内容的id集合，获取所有内容的文本
export const getSectionsContentStatus = (sections: Section[]) => {
  // const flattenSection = flattenSections(sections);
  const ids: string[] = [];
  const textDocGroup: any[] = [];
  const allTextGroup: any[] = [];

  const changeSectionValue = (sections: NewSection[], level = 1) => {
    for (const section of sections) {
      const selectIndex = section.selectIndex ?? 0;
      const selectContent = section.sectionContents?.length ? section.sectionContents[selectIndex].context : '';
      if (level > 1) {
        if (!selectContent) {
          ids.push(section.sectionId!);
        }
      }
      if (selectContent) {
        textDocGroup.push(
          {
            type: 'paragraph',
            children: [
              {
                text: section.sectionTitle,
              },
            ],
            heading: {
              type: `h${level + 2}`,
            },
          },
          ...selectContent.split('\n').map(item => ({
            type: 'paragraph',
            children: [
              {
                text: item ?? '',
              },
            ],
          })),
        );
        allTextGroup.push(section.sectionTitle, selectContent);
      } else {
        textDocGroup.push({
          type: 'paragraph',
          children: [
            {
              text: section.sectionTitle,
            },
          ],
          heading: {
            type: `h${level + 2}`,
          },
        });
        allTextGroup.push(section.sectionTitle);
      }
      if (section.subLevels) {
        changeSectionValue(section.subLevels, level + 1);
      }
    }
  };
  changeSectionValue(sections);
  return { ids, textDocGroup, allTexts: allTextGroup.join('\n') };
};

// 重新格式化section的排序字段
export const refineSectionSortNum = (sections: NewSection[]) => {
  if (Array.isArray(sections)) {
    for (let index = 0; index < sections.length; index++) {
      const section = sections[index];
      section.sortNum = index + 1;
    }
  }
  return sections;
};
