import { model } from '@modern-js/runtime/model';
import {
  AdvancedWritingArticleGenerateReq,
  AdvancedWritingOutlineGenerateReq,
  AdvancedWritingOutlineQueryReq,
  AdvancedWritingOutlineSaveReq,
  AdvancedWritingParagraphGenerateReq,
  AdvancedWritingParagraphGenerateResp,
  AdvancedWritingParagraphOutlineCreateReq,
  AdvancedWritingParagraphOutlineDeleteReq,
  AdvancedWritingParagraphOutlineGenerateReq,
  AdvancedWritingParagraphOutlineGenerateResp,
  QueryOutlineWritingInfoReq,
  QueryOutlineWritingInfoResp,
  Section,
} from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import {
  changeSectionValue,
  flattenSections,
  changeSectionModifiedTag as changeSectionModifiedTagUtil,
  removeSectionModifiedTag as removeSectionModifiedTagUtil,
  getSectionByPath,
  getSectionModifiedState,
  refineSectionSortNum,
  getSectionById,
  getSectionLoadingState,
} from './utils';

import { countWords } from '@/views/writing/util';
import {
  advancedWritingArticleGenerate,
  advancedWritingOutlineGenerate,
  advancedWritingOutlineQuery,
  advancedWritingOutlineSave,
  advancedWritingParagraphContentCreate,
  advancedWritingParagraphGenerate,
  AdvancedWritingParagraphOutlineCreate,
  AdvancedWritingParagraphOutlineDelete,
  advancedWritingParagraphOutlineGenerate,
  QueryOutlineWritingInfo,
} from '@/api/copilot_api';
import { NewSection } from '@/type/advanceWriting';

export interface Props {
  sections: NewSection[]; // 章节集合，包括大纲和内容
  /** 生成大纲或者全文的表单参数 **/
  writingParams: {
    writingRequirements: string;
    genre: string; // 文章类型，目前是保存后端接口每次自动返回的内容并且传回去
    selectedKeywords: string[];
    reference: Array<{
      name: string;
      type: string;
      content: string;
      loading?: boolean;
    }>;
    replyLanguage: string;
  };
  currentStep: number;
  isGeneratingOutline: boolean;
  isGeneratingArticle: boolean;
  initialized: boolean;
  articleInfo: QueryOutlineWritingInfoResp;
  enableRetrieval: boolean;
}

const AdvancedWritingStore = model<Props>('AdvancedWritingStore').define((context, { use }) => {
  return {
    state: {
      sections: [],
      writingParams: {
        writingRequirements: '',
        genre: '',
        selectedKeywords: [],
        reference: [],
        replyLanguage: '',
      },
      currentStep: 1,
      isGeneratingOutline: false,
      isGeneratingArticle: false,
      initialized: false,
      articleInfo: {},
      enableRetrieval: false,
    },
    actions: {
      setSections(state, payload) {
        state.sections = payload;
      },
      /**
       * 储存章节历史的index集合，用于切换章节时定位
       * @param payload key为sectionId，value为index
       */
      setSelectIndex(state, payload: { sectionId: string; position: 1 | -1 }) {
        const { sections } = state;
        const { sectionId, position } = payload;
        changeSectionValue(sections, sectionId, position);
      },
      /**
       * 删除概要
       * @param payload 需要删除概要的章的index
       */
      deleteSummary(state, payload: number[]) {
        const { sections } = state;
        const lastIndex = payload.pop() || 0;
        sections[lastIndex].sectionContents = [];
        sections[lastIndex].selectIndex = undefined;
      },
      /**
       * 新增概要
       * @param payload 需要更新概要的章的index
       */
      addSummary(state, payload: { section: Section; path: number[] }) {
        const { sections } = state;
        const { section, path } = payload;
        const lastIndex = path.pop() || 0;
        if (section?.sectionContents?.[0]) {
          sections[lastIndex].sectionContents?.push(section.sectionContents[0]);
        }
      },
      /**
       * 删除章或节
       * @param path 章节的path，目前只考虑两级
       */
      deleteSection: {
        pending(draft) {
          console.log(draft);
        },
        fulfilled(state, data) {
          const { sections } = state;
          const { result, params, path = [] } = data;

          // path最后一位
          const lastIndex = path.pop() || 0;
          // 当前index对应的节点
          let currentSection = sections;

          for (const currentIndex of path) {
            currentSection = sections[currentIndex].subLevels || [];
          }
          // 删除对应节点
          currentSection.splice(lastIndex, 1);
          refineSectionSortNum(currentSection);
        },
        rejected(_draft, error) {
          console.log('error======>', error);
        },
      },
      /**
       * 增加章或节
       * @param payload section，增加的章节结构
       */
      addSection: {
        pending(draft) {
          console.log(draft);
        },
        fulfilled(state, data) {
          const { sections } = state;
          const { result, params, path = [] } = data;

          // 当前index对应的节点
          let currentSection = sections;

          for (const currentIndex of path) {
            currentSection = sections[currentIndex].subLevels || [];
          }
          // 增加对应节点
          currentSection.push({
            sectionId: result.sectionId,
            sectionContents: [],
            subLevels: [],
            sectionTitle: '',
          });
          refineSectionSortNum(currentSection);
        },
        rejected(_draft, error) {
          console.log('error======>', error);
        },
      },
      /**
       * 更新内容
       * @param payload value：更新的概要的内容，path：需要更新概要的章的index的集合，sectionContentsIndex：章节内容history的index
       */
      updateSectionContent(state, payload: { value: string; path: number[]; sectionContentsIndex: number }) {
        const { sections } = state;
        const { value, path, sectionContentsIndex } = payload;

        // path最后一位
        const lastIndex = path.pop() || 0;
        // 当前index对应的节点
        let currentSection = sections;

        for (const currentIndex of path) {
          currentSection = sections[currentIndex].subLevels || [];
        }
        // 更改对应节点的content
        // 没有版本就新增一个版本
        if (!currentSection[lastIndex].sectionContents?.length) {
          currentSection[lastIndex].sectionContents = [{ context: '', version: '0' }];
        }
        if (currentSection[lastIndex].sectionContents) {
          currentSection[lastIndex].sectionContents![sectionContentsIndex].context = value;
        }
      },
      /**
       * 更新sectionTitle
       * @param payload value：更新的sectionTitle，path：需要更新概要的章的index的集合
       */
      updateSectionTitle(state, payload: { value: string; path: number[] }) {
        const { sections } = state;
        const { value, path } = payload;

        // path最后一位
        const lastIndex = path.pop() || 0;
        // 当前index对应的节点
        let currentSection = sections;

        for (const currentIndex of path) {
          currentSection = sections[currentIndex].subLevels || [];
        }
        // 更改对应节点的content
        currentSection[lastIndex].sectionTitle = value;
      },
      /**
       * 更新大纲，点击 Outline regeneration/refresh 按钮的时候
       * @param payload section，大纲结构；path：全部大纲不传path
       */
      updateSectionOutline(state, payload: { section: Section[]; path?: number[] }) {
        const { sections } = state;
        const { section, path = [] } = payload;

        // 全部大纲重新生成，清空历史，点击 Outline regeneration 的时候
        if (path.length === 0) {
          state.sections = section;
          return;
        }

        // path最后一位
        const lastIndex = path.pop() || 0;
        // 当前index对应的节点
        let currentSection = sections;

        for (const currentIndex of path) {
          currentSection = sections[currentIndex].subLevels || [];
        }
        // 更改对应节点的subLevels
        currentSection[lastIndex].subLevels = sections;
      },
      /**
       * 更新大纲，如更新大纲标题
       * @param payload section，大纲结构；path：全部大纲不传path
       */
      updateSection(state, payload: { section: Section; path?: number[] }) {
        const { sections } = state;
        const { section, path = [] } = payload;

        if (path.length === 0) {
          return;
        }
        // 找到path对应的section
        let currentSection: Section = sections[path[0]];

        for (const currentIndex of path.slice(1)) {
          if (!currentSection.subLevels) {
            return;
          }
          currentSection = currentSection.subLevels[path[currentIndex]];
        }
        // 更改对应section的属性
        const attrKeys = Object.keys(section) as (keyof Section)[];
        for (const attrKey of attrKeys) {
          if (attrKey in currentSection && typeof currentSection[attrKey] === typeof section[attrKey]) {
            currentSection[attrKey] = section[attrKey] as any; // 暂时处理一下
          }
        }
      },
      /**
       * 调换顺序
       * @param payload path为需要调换顺序的节或章的index，direction为-1表示上移，为1表示下移
       */
      exchangeSection(state, payload: { path: number[]; direction: -1 | 1 }) {
        const { sections } = state;
        const { path, direction } = payload;
        // 数组调换顺序
        const exchangeIndex = (originalArray: Section[] = [], index1: number, index2: number) => {
          if (index1 !== index2 && index1 >= 0 && index1 < originalArray.length && index2 >= 0 && index2 < originalArray.length) {
            return originalArray.map((item, index) => {
              if (index === index1) {
                return { ...originalArray[index2], sortNum: originalArray[index1].sortNum };
              } else if (index === index2) {
                return { ...originalArray[index1], sortNum: originalArray[index2].sortNum };
              } else {
                return item;
              }
            });
          } else {
            return originalArray;
          }
        };

        if (path.length === 1) {
          state.sections = exchangeIndex(sections, path[0], path[0] + direction);
          return;
        }

        // path最后一位
        const lastIndex = path.pop() || 0;
        // path倒数第二位
        const lastIndex1 = path.pop() || 0;

        // 当前index对应的节点
        let currentSection = sections;

        for (const currentIndex of path) {
          currentSection = sections[currentIndex].subLevels || [];
        }

        // 更改对应节点的subLevels
        currentSection[lastIndex1].subLevels = exchangeIndex(currentSection[lastIndex1].subLevels, lastIndex, lastIndex + direction);
        refineSectionSortNum(currentSection);
      },
      /**
       * 全文大纲生成
       */
      generateAdvancedWritingOutline: {
        pending(draft) {
          draft.isGeneratingOutline = true;
        },
        fulfilled(state, data: Section[]) {
          // const traversal = (sectionsTemp: NewSection[]) => {
          //   for (const temp of sectionsTemp) {
          //     if (!temp.sectionContents) {
          //       temp.sectionContents = [];
          //     }
          //   }
          // };
          // traversal(data);
          // console.log('data======>', data);
          state.sections = data;
          state.isGeneratingOutline = false;
        },
        rejected(_draft, error) {
          console.log('error======>', error);
          _draft.isGeneratingOutline = false;
        },
      },
      /**
       * 章节大纲生成
       */
      generateAdvancedWritingParagraphOutline: {
        pending(draft) {
          console.log(draft);
        },
        fulfilled(state, data: { params: AdvancedWritingParagraphOutlineGenerateReq; result: AdvancedWritingParagraphOutlineGenerateResp }) {
          const { sections } = state;
          const { result } = data;
          const { section } = result;

          const newSectionMap = flattenSections(section ? [section] : []);
          const traversal = (sectionsTemp: NewSection[]) => {
            for (const temp of sectionsTemp) {
              const nowSection = newSectionMap.find(item => item.sectionId === temp.sectionId);
              if (nowSection) {
                temp.sectionContents = nowSection.sectionContents ?? [];
                temp.subLevels = nowSection.subLevels;
                temp.selectIndex = undefined; // 清空选中
                return;
              }
              if (temp.subLevels && temp.subLevels.length > 0) {
                traversal(temp.subLevels);
              }
            }
          };
          traversal(sections);
        },
        rejected(_draft, error) {
          console.log('error======>', error);
        },
      },
      /**
       * 全文内容生成
       */
      generateAdvancedWritingArticle: {
        pending(draft) {
          draft.isGeneratingArticle = true;
        },
        fulfilled(state, data: NewSection[]) {
          const { sections } = state;
          // 全文内容重新生成，则文章sectionHistory全部+1，右侧大纲不变，清空sectionHistory，展示最新的index
          // TODO: 是否编辑逻辑判断
          const newSectionMap = flattenSections(data);
          const traversal = (sectionsTemp: NewSection[]) => {
            for (const section of sectionsTemp) {
              const nowSection = newSectionMap.find(item => item.sectionId === section.sectionId);
              if (nowSection) {
                section.sectionContents = [...(nowSection.sectionContents ?? []), ...(section.sectionContents ?? [])];
                section.selectIndex = undefined; // 清空选中
              }
              if (section.subLevels && section.subLevels.length > 0) {
                traversal(section.subLevels);
              }
            }
          };
          traversal(sections);

          state.isGeneratingArticle = false;
        },
        rejected(_draft, error) {
          console.log('error======>', error);
          _draft.isGeneratingArticle = false;
        },
      },
      /**
       * 章节内容生成
       */
      generateAdvancedWritingParagraph: {
        pending(draft) {
          console.log(draft);
        },
        fulfilled(state, data: { params: AdvancedWritingParagraphGenerateReq; result: AdvancedWritingParagraphGenerateResp }) {
          const { sections } = state;
          const { params, result = {} } = data;
          const { includeSubsection, cleanupHistory } = params;
          const { section } = result;

          if (!section) {
            return;
          }

          const newSectionList = includeSubsection ? flattenSections(section.subLevels || []) : flattenSections([section]);

          for (const newSection of newSectionList) {
            const updateSection = getSectionById(sections, newSection.sectionId);
            console.log(updateSection, '<------ update section');
            if (updateSection) {
              if (cleanupHistory) {
                updateSection.sectionContents = newSection.sectionContents ?? [];
              } else {
                updateSection.sectionContents = [...(newSection.sectionContents ?? []), ...(updateSection.sectionContents ?? [])];
              }
              updateSection.selectIndex = undefined;
            }
          }
        },
        rejected(_draft, error) {
          console.log('error======>', error);
        },
      },
      /**
       * 大纲内容实时保存
       */
      saveAdvancedWritingOutline: {
        pending(draft) {
          console.log(draft);
        },
        fulfilled(state, data: Section[]) {
          // TODO:
        },
        rejected(_draft, error) {
          console.log('error======>', error);
        },
      },
      setWritingParams(draft, payload: Partial<Props['writingParams']>) {
        if (payload.genre !== undefined) {
          draft.writingParams.genre = payload.genre;
        }
        if (payload?.reference) {
          draft.writingParams.reference = payload.reference;
        }
        if (payload?.selectedKeywords) {
          draft.writingParams.selectedKeywords = payload.selectedKeywords;
        }
        if (payload.writingRequirements !== undefined) {
          draft.writingParams.writingRequirements = payload.writingRequirements;
        }
        if (payload.replyLanguage) {
          draft.writingParams.replyLanguage = payload.replyLanguage;
        }
      },
      changeSectionModifiedTag(draft, payload) {
        const { path, event } = payload;
        const { sections } = draft;
        changeSectionModifiedTagUtil(sections, path, event);
      },
      removeSectionModifiedTag(draft, payload) {
        const { path, sectionId, event } = payload;

        const section = sectionId ? getSectionById(draft.sections, sectionId) : getSectionByPath(draft.sections, path);
        if (section) {
          removeSectionModifiedTagUtil([section], event);
        }
      },
      changeSectionLoadingState(draft, payload) {
        const { path = [], sectionId, isGeneratingContent = false, isGeneratingOutline = false, isGeneratingSummary = false } = payload;
        const currentSection = sectionId ? getSectionById(draft.sections, sectionId) : getSectionByPath(draft.sections, path);
        if (currentSection) {
          currentSection.isGeneratingContent = isGeneratingContent;
          currentSection.isGeneratingOutline = isGeneratingOutline;
          currentSection.isGeneratingSummary = isGeneratingSummary;
        }
      },
      /**
       * 大纲基本信息查询
       */
      queryadvancedWritingInfo: {
        pending(draft) {
          console.log(draft);
        },
        fulfilled(state, data) {
          const { writingParams } = state;
          writingParams.genre = data.genre;
          writingParams.selectedKeywords = data.keywords.filter((e: string) => e.length);
          writingParams.reference = Array.isArray(data.reference) ? data.reference : [];
          writingParams.writingRequirements = data.writingRequirements || '';
          writingParams.replyLanguage = data.replyLanguage || '';

          // 保存文档信息
          state.articleInfo = data;
        },
        rejected(_draft, error) {
          console.log('error======>', error);
        },
      },
      queryadvancedWritingOutline: {
        pending(draft) {
          console.log(draft);
        },
        fulfilled(state, data) {
          if (data !== null) {
            state.sections = data.outline || [];
            if (data.outline?.length) {
              state.currentStep = 3;
            } else {
              state.currentStep = 1;
            }
          }
        },
        rejected(_draft, error) {
          console.log('error======>', error);
        },
      },
      resetStore(draft) {
        draft.sections = [];
        draft.initialized = false;
        draft.isGeneratingArticle = false;
        draft.isGeneratingOutline = false;
        draft.articleInfo = {};
      },
    },
    effects: {
      // TODO: 判断是否已有手动编辑的内容
      // 全文大纲生成；如果当前已有手动编辑内容，出弹窗，点击确定后，重新生成大纲+空内容
      async generateAdvancedWritingOutline(params: AdvancedWritingOutlineGenerateReq) {
        const { outline } = await advancedWritingOutlineGenerate(params);
        return outline;
      },
      // 章节大纲生成；如果当前已有手动编辑内容，出弹窗。同时重新生成小节+左侧空内容
      // TODO: 判断是否已有手动编辑的内容
      async generateAdvancedWritingParagraphOutline(params: AdvancedWritingParagraphOutlineGenerateReq) {
        const result = await advancedWritingParagraphOutlineGenerate(params);
        return { result, params };
      },
      // 全文内容重新生成；如果当前已有手动编辑内容；则左侧内容history全部+1，右侧大纲不变
      // TODO: 判断是否已有手动编辑的内容
      async generateAdvancedWritingArticle(params: AdvancedWritingArticleGenerateReq) {
        const { outline } = await advancedWritingArticleGenerate(params);
        return outline;
      },
      // 章节内容重新生成；如果当前已有手动编辑内容，出弹窗；则左侧章内容全部+1，右侧大纲不变
      // TODO: 判断是否已有手动编辑的内容
      async generateAdvancedWritingParagraph(params: AdvancedWritingParagraphGenerateReq) {
        const result = (await advancedWritingParagraphGenerate(params)) ?? {};
        return { params, result };
      },
      // 大纲内容实时保存
      async saveAdvancedWritingOutline(params: AdvancedWritingOutlineSaveReq) {
        const data = await advancedWritingOutlineSave(params);
        return data;
      },
      // 大纲内容查询
      async queryadvancedWritingOutline(params: AdvancedWritingOutlineQueryReq) {
        const data = await advancedWritingOutlineQuery(params);
        return data;
      },
      // 大纲基本信息查询
      async queryadvancedWritingInfo(params: QueryOutlineWritingInfoReq) {
        const data = await QueryOutlineWritingInfo(params);
        return data;
      },
      // 删除章节
      async deleteSection(params: AdvancedWritingParagraphOutlineDeleteReq, path?: number[]) {
        const result = await AdvancedWritingParagraphOutlineDelete(params);
        return { result, params, path };
      },
      // 新增章节
      async addSection(params: AdvancedWritingParagraphOutlineCreateReq, path?: number[]) {
        const result = await AdvancedWritingParagraphOutlineCreate(params);
        return { result, params, path };
      },
      // 更新节内容
      /**
       * 更新内容
       * @param payload value：更新的概要的内容，path：需要更新概要的章的index的集合，sectionContentsIndex：章节内容history的index
       */
      async updateSectionContentWithAutoCreate(payload: { value: string; path: number[]; sectionContentsIndex: number; articleId?: string }) {
        const [state, actions] = use(AdvancedWritingStore);
        const { sections } = state;
        const { path, articleId } = payload;

        // path最后一位
        const lastIndex = path[path.length - 1] || 0;
        // 当前index对应的节点
        let currentSection = sections;

        for (const currentIndex of path.slice(0, path.length - 1)) {
          currentSection = sections[currentIndex].subLevels || [];
        }
        // 没有版本就新增一个版本
        if (!currentSection[lastIndex].sectionContents?.length) {
          // 通过api新增版本，因为这个action用在了用户键盘输入的时候，不考虑也没法考虑后端新增版本失败的情况
          advancedWritingParagraphContentCreate({
            articleId,
            sectionId: currentSection[lastIndex].sectionId,
            sectionContent: { context: '', version: '0' },
          }).catch(err => {
            console.error('[create paragraph content error]', err);
          });
        }
        actions.updateSectionContent({
          path: path.slice(),
          sectionContentsIndex: payload.sectionContentsIndex,
          value: payload.value,
        });
      },
    },
    computed: {
      // 统计字数
      sectionsTextCount: state => {
        const { sections } = state;
        // 计算当前level下的字数
        const textCounts = (s: NewSection[] = []) => {
          return s.reduce((prev, item) => {
            const selectIndex = item.selectIndex ?? (item.sectionContents ? item.sectionContents.length - 1 : 0);
            return prev + countWords(item.sectionTitle ?? '') + countWords(item.sectionContents?.[selectIndex]?.context ?? '');
          }, 0);
        };
        // 章的内容的总字数的集合，[10,10,10]
        const sectionsContentTextCount = sections.map(item => {
          const summaryIndex = item.selectIndex ?? (item.sectionContents ? item.sectionContents.length - 1 : 0);
          return textCounts(item.subLevels) + countWords(item.sectionContents?.[summaryIndex]?.context ?? '') + countWords(item.sectionTitle ?? '');
        });
        // 总字数
        const total = sectionsContentTextCount.reduce((prev, item) => {
          return prev + item;
        }, 0);
        return {
          total,
          sectionsContentTextCount,
        };
      },
      // 根据章节位置获取章节内容和大纲是否被修改
      sectionModifiedState:
        state =>
        ({ path, sectionId, includeSubsection = true }: { path?: number[]; sectionId?: string; includeSubsection?: boolean }) => {
          const { sections } = state;
          let currentSection;
          if (sectionId) {
            currentSection = getSectionById(sections, sectionId);
          }
          if (path?.length) {
            currentSection = getSectionByPath(sections, path);
          }
          if (currentSection) {
            return getSectionModifiedState(currentSection, includeSubsection);
          } else {
            let isContentModified = false;
            let isOutlineModified = false;
            for (const section of sections) {
              const { isContentModified: isSubSectionContentModified, isOutlineModified: isSubSectionOutlineModified } = getSectionModifiedState(section);
              isContentModified = isContentModified || isSubSectionContentModified;
              isOutlineModified = isOutlineModified || isSubSectionOutlineModified;
            }
            return { isContentModified, isOutlineModified };
          }
        },

      sectionLoadingState:
        state =>
        ({ path, sectionId }: { path?: number[]; sectionId?: string }) => {
          const { sections } = state;
          let currentSection;
          if (sectionId) {
            currentSection = getSectionById(sections, sectionId);
          }
          if (path?.length) {
            currentSection = getSectionByPath(sections, path);
          }
          if (currentSection) {
            return getSectionLoadingState(currentSection);
          }
          return null;
        },
    },
  };
});

export default AdvancedWritingStore;
