import { Input, Message, Space, Spin } from '@arco-design/web-react';
import { IconCopy, IconLeft, IconRight } from '@arco-design/web-react/icon';
import { useModel } from '@modern-js/runtime/model';
import classNames from 'classnames';
import { useMemo } from 'react';
import useLocale from '@hooks/useLocale';
import { writeToClipboard } from '@hkgai/slate-plugin/dist/lib/core/clipboard/utils/serialize';
import { IconProofreadingStartState } from '@arco-iconbox/react-hkgai-government-icon';
import styles from './index.module.scss';
import WordCount from './wordCount';
import { getSectionsContentStatus } from '@/store/advancedWritingStore/utils';
import AdvancedWritingStore from '@/store/advancedWritingStore';
import { SectionModifiedEvent } from '@/type/advanceWriting';
import AiWritingStore from '@/store/aiWritingStore';
import { useArticleId } from '@/hooks/article';

const ArticleContent = () => {
  const articleId = useArticleId();
  const locale = useLocale();
  const [
    { sections = [], sectionsTextCount, writingParams, isGeneratingArticle, isGeneratingOutline },
    { setSelectIndex, updateSectionContent, updateSectionContentWithAutoCreate, changeSectionModifiedTag },
  ] = useModel(AdvancedWritingStore);
  const [{ advanceList }] = useModel(AiWritingStore);
  const { total = 0, sectionsContentTextCount } = sectionsTextCount;
  // 历史点击prev
  const onPrevClick = (sectionId: string) => {
    setSelectIndex({ sectionId, position: 1 });
  };
  // 历史点击next
  const onNextClick = (sectionId: string) => {
    setSelectIndex({ sectionId, position: -1 });
  };

  const title = useMemo(() => {
    const t = advanceList.find(item => item.articleId === articleId)?.title;
    return <div className={styles.articleTitle}>{t ?? writingParams.writingRequirements}</div>;
  }, [advanceList, articleId, writingParams]);

  const content = useMemo(
    () =>
      sections.map((item, index) => {
        const sectionContentsLength = item.sectionContents?.length || 0;
        const sectionContentsIndex = item.selectIndex ?? 0;
        return (
          <div className={styles.sectionContainer} key={item.sectionId}>
            <Spin loading={item.isGeneratingOutline} className="w-full">
              <div className={styles.sectionTitle} id={item.sectionId}>
                <div className={styles.sectionTitleText}>{item.sectionTitle}</div>
                <Space className={styles.actions} size={12}>
                  <div
                    className={styles.action}
                    onClick={() => {
                      const { textDocGroup, allTexts } = getSectionsContentStatus([item]);
                      writeToClipboard({
                        'application/x-doc-editor': JSON.stringify(textDocGroup),
                        'text/plain': allTexts,
                      });
                      Message.success(locale['copy.success.toast']);
                    }}
                  >
                    <IconCopy /> {locale.ad_writing_copy}
                  </div>
                  <WordCount number={sectionsContentTextCount[index]} />
                </Space>
              </div>
              {sectionContentsLength > 0 && (
                <Spin loading={item.isGeneratingSummary} className="w-full">
                  <div className={classNames(styles.sectionMiniTitle, styles.sectionMiniTitleSummary)}>
                    <div className={styles.sectionMiniTitleText}></div>
                    <div className={styles.sectionMiniTitleActions}>
                      <div
                        className={classNames(styles.left, { [styles.disabled]: sectionContentsIndex + 1 >= sectionContentsLength })}
                        onClick={() => onPrevClick(item.sectionId!)}
                      >
                        <IconLeft />
                        {sectionContentsLength - sectionContentsIndex}
                      </div>
                      <div className={styles.mid}>/</div>
                      <div className={classNames(styles.right, { [styles.disabled]: sectionContentsIndex <= 0 })} onClick={() => onNextClick(item.sectionId!)}>
                        {sectionContentsLength}
                        <IconRight />
                      </div>
                    </div>
                  </div>
                  <Input.TextArea
                    className={styles.sectionContent}
                    autoSize={{ minRows: 1 }}
                    value={item.sectionContents?.[sectionContentsIndex]?.context}
                    onChange={value => {
                      updateSectionContent({ value, path: [index], sectionContentsIndex });
                      changeSectionModifiedTag({ event: SectionModifiedEvent.SectionContentModified, path: [index] });
                    }}
                  />
                </Spin>
              )}
              {item.subLevels?.map((subItem, subIndex) => {
                const sectionContentsLength = subItem.sectionContents?.length || 0;
                // 后端返回的数据 sectionContents 是倒序的
                const sectionContentsIndex = subItem.selectIndex ?? 0;
                return (
                  <div className={styles.sectionMiniWrap} key={subItem.sectionId}>
                    <Spin loading={subItem.isGeneratingContent} className={'w-full'}>
                      <div className={styles.sectionMiniTitle} id={subItem.sectionId}>
                        <div className={styles.sectionMiniTitleText}>{subItem.sectionTitle}</div>
                        <div className={styles.sectionMiniTitleActions}>
                          {/** 所以向前和向后翻页的计算方式要反着计算 */}
                          <div
                            className={classNames(styles.left, { [styles.disabled]: sectionContentsIndex >= sectionContentsLength - 1 })}
                            onClick={() => onPrevClick(subItem.sectionId!)}
                          >
                            <IconLeft />
                            {sectionContentsLength - sectionContentsIndex}
                          </div>
                          <div className={styles.mid}>/</div>
                          <div className={classNames(styles.right, { [styles.disabled]: sectionContentsIndex <= 0 })} onClick={() => onNextClick(subItem.sectionId!)}>
                            {sectionContentsLength}
                            <IconRight />
                          </div>
                        </div>
                      </div>
                      <Input.TextArea
                        className={styles.sectionContent}
                        autoSize={{ minRows: 1 }}
                        value={subItem.sectionContents?.[sectionContentsIndex]?.context}
                        onChange={value => {
                          updateSectionContentWithAutoCreate({ value, path: [index, subIndex], sectionContentsIndex, articleId });
                          changeSectionModifiedTag({ event: SectionModifiedEvent.SectionContentModified, path: [index, subIndex] });
                        }}
                      />
                    </Spin>
                  </div>
                );
              })}
            </Spin>
          </div>
        );
      }),
    [sections, locale],
  );

  if (!sections || sections.length === 0) {
    return (
      <div className={classNames(styles.container, 'h-full', 'flex', 'items-center', 'justify-center', 'flex-col')}>
        <IconProofreadingStartState />
        <div className="mt-[-30px] text-[#666] text-[16px]">{locale.ad_writing_empty}</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.toolBar}>
        <Space className={styles.actions} size={12}>
          <div
            className={styles.action}
            onClick={() => {
              const { textDocGroup, allTexts } = getSectionsContentStatus(sections);
              writeToClipboard({
                'application/x-doc-editor': JSON.stringify(textDocGroup),
                'text/plain': allTexts,
              });
              Message.success(locale['copy.success.toast']);
            }}
          >
            <IconCopy /> {locale.ad_writing_copy_entire_text}
          </div>
          <WordCount number={total} />
        </Space>
      </div>
      <div className={`${styles.articleContainer} ${isGeneratingOutline || isGeneratingArticle ? 'overflow-hidden' : ''}`}>
        <Spin className="w-full" loading={isGeneratingOutline || isGeneratingArticle}>
          {title}
          {content}
        </Spin>
        {/* <div className={styles.articleTitle}>{title ?? writingParams.writingRequirements}</div>\ */}
      </div>
    </div>
  );
};

export default ArticleContent;
