import { Layout } from '@arco-design/web-react';
import { useModel } from '@modern-js/runtime/model';
import { useRequest, useUnmountedRef } from 'ahooks';
import { useEffect } from 'react';
import ArticleContent from './components/articleContent';
import Form from './components/generate-outline-form';
import { RequireAuth } from '@/routes/auth';
import GlobalStore from '@/store/globalStore';
import Footer from '@/components/footer';
import AdvancedWritingStore from '@/store/advancedWritingStore';
import { useArticleId } from '@/hooks/article';
import { EditOutlineWritingInfo } from '@/api/copilot_api';
import './index.scss';
import useLocale from '@/hooks/useLocale';
import { useRoutingBlocker } from '@/hooks/usRoutingBlocker';

const Index = () => {
  const locale = useLocale();

  const [{ isSiderBarDrawerVisible }] = useModel(GlobalStore);
  const [
    { sections, writingParams, initialized, sectionLoadingState },
    { saveAdvancedWritingOutline, queryadvancedWritingOutline, queryadvancedWritingInfo, setInitialized, resetStore },
  ] = useModel(AdvancedWritingStore);
  const { current: isUnmounted } = useUnmountedRef();

  const { run: runSave } = useRequest(saveAdvancedWritingOutline, {
    throttleWait: 1000,
    manual: true,
    // retryCount: 3, // 请求失败后重试3次
    throttleLeading: false,
  });

  const articleId = useArticleId();

  const { run: saveOutlineWritingInfo } = useRequest(EditOutlineWritingInfo, {
    throttleWait: 1000,
    manual: true,
    throttleLeading: false,
  });

  // TODO: 实时保存，加一个状态，请求完大纲后，再监听
  useEffect(() => {
    if (initialized && sections && sections.length !== 0) {
      runSave({
        articleId,
        outline: sections,
      });
    }
  }, [sections]);

  useEffect(() => {
    // 文档id切换时重置store
    resetStore();
    if (articleId) {
      Promise.all([queryadvancedWritingOutline({ articleId }), queryadvancedWritingInfo({ articleId })]).then(() => {
        // 避免页面已经卸载的情况下promise返回的情况？
        if (!isUnmounted) {
          setInitialized(true);
        } else {
          resetStore();
        }
      });
    }
    // 页面卸载时重置store
    return () => {
      resetStore();
    };
  }, [articleId]);

  useEffect(() => {
    if (initialized) {
      saveOutlineWritingInfo({
        ...writingParams,
        articleId,
        keywords: writingParams.selectedKeywords,
      });
    }
  }, [writingParams]);

  useRoutingBlocker(({ nextLocation, currentLocation, historyAction }) => {
    if (nextLocation.key !== currentLocation.key) {
      for (const section of sections) {
        const { isGeneratingContent, isGeneratingOutline, isGeneratingSummary, isSubSectionGeneratingContent, isSubSectionGeneratingOutline, isSubSectionGeneratingSummary } =
          sectionLoadingState({ sectionId: section.sectionId }) || {};
        // 正在生成中，终止路由跳转
        if (isGeneratingContent || isGeneratingOutline || isGeneratingSummary || isSubSectionGeneratingContent || isSubSectionGeneratingOutline || isSubSectionGeneratingSummary) {
          return true;
        }
      }
    }
    return false;
  }, locale.ad_writing_generating_notification);

  return (
    <RequireAuth>
      <Layout className="h-full gap-2">
        <Layout.Content className="bg-white rounded-tr-lg">
          <Layout className="h-full">
            <Layout.Content className="overflow-auto">
              <ArticleContent />
            </Layout.Content>
            <Layout.Footer>
              <Footer />
            </Layout.Footer>
          </Layout>
        </Layout.Content>
        <Layout.Sider className={`rounded-tl-lg overflow-hidden transition ${isSiderBarDrawerVisible ? 'hidden' : ''}`} width={360}>
          <Form key={articleId} />
        </Layout.Sider>
      </Layout>
    </RequireAuth>
  );
};

export default Index;
