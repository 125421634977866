import { FC } from 'react';
import { Section as SectionType } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import { useModel } from '@modern-js/runtime/model';
import { Button, Message, Modal } from '@arco-design/web-react';
import { IconPlus } from '@arco-iconbox/react-hkgai-fe-icon';
import Section from './section';
import AdvancedWritingStore from '@/store/advancedWritingStore';
import { getSectionByPath } from '@/store/advancedWritingStore/utils';
import useLocale from '@/hooks/useLocale';
import { useArticleId } from '@/hooks/article';
import { NewSection, SectionModifiedEvent } from '@/type/advanceWriting';

const Outline: FC<{ sections: SectionType[]; onRegenerateSubSectionsInSections: (sections: NewSection[], globalCleanupHistory?: boolean) => Promise<void> }> = ({
  sections,
  onRegenerateSubSectionsInSections,
}) => {
  const locale = useLocale();

  const [
    { sectionModifiedState, enableRetrieval, isGeneratingArticle, isGeneratingOutline },
    {
      exchangeSection,
      deleteSection,
      addSection,
      generateAdvancedWritingParagraphOutline,
      generateAdvancedWritingParagraph,
      updateSectionTitle,
      deleteSummary,
      changeSectionLoadingState,
      removeSectionModifiedTag,
    },
  ] = useModel(AdvancedWritingStore);

  const articleId = useArticleId();
  return (
    <div className="space-y-2 h-full">
      {sections.map((section, index) => (
        <div className="p-2 rounded-lg bg-[#F9F9F9] overflow-hidden text-xs" key={`${section.sectionId}-${index}`}>
          <Section
            key={section.sectionId}
            disabled={isGeneratingArticle || isGeneratingOutline}
            section={section}
            canAddSummary
            canAddSubSection
            canRefreshSection
            path={[index]}
            canMoveUp={index !== 0}
            canMoveDown={index !== sections.length - 1}
            onClickMoveUp={path => {
              exchangeSection({ path, direction: -1 });
            }}
            onClickMoveDown={path => {
              exchangeSection({ path, direction: 1 });
            }}
            onClickDelete={path => {
              const section = getSectionByPath(sections, path);
              deleteSection({ articleId, sectionId: section?.sectionId }, path);
            }}
            onRefreshSectionOutline={path => {
              const refreshSection = getSectionByPath(sections, path);

              const { isContentModified, isOutlineModified } = sectionModifiedState({ path });
              const regenerate = async () => {
                changeSectionLoadingState({ path, isGeneratingOutline: true });
                try {
                  await generateAdvancedWritingParagraphOutline({ sectionId: refreshSection?.sectionId, articleId });
                } catch (error) {
                  Message.error(locale.ad_writing_generate_outline_error);
                } finally {
                  changeSectionLoadingState({ path, isGeneratingOutline: false });
                }
              };
              if (isContentModified || isOutlineModified) {
                Modal.confirm({
                  title: locale.ad_writing_regenerate,
                  content: locale.ad_writing_subsection_content,
                  onConfirm: regenerate,
                });
              } else {
                regenerate();
              }
            }}
            onRefreshSection={path => {
              const refreshSection = getSectionByPath(sections, path);
              // 判断章标题有没有修改过
              const { isOutlineModified: isSectionTitleModified } = sectionModifiedState({ path, includeSubsection: false });
              // 判断节标题有没有修改过
              const { isContentModified, isOutlineModified } = sectionModifiedState({ path });
              if (!refreshSection) {
                return;
              }
              const generate = () => {
                onRegenerateSubSectionsInSections([refreshSection], isSectionTitleModified);
              };

              if (isContentModified || isOutlineModified) {
                Modal.confirm({
                  title: locale.ad_writing_regenerate,
                  content: locale.ad_writing_subsection_content,
                  onConfirm: generate,
                });
              } else {
                generate();
              }
            }}
            onRefreshSubSection={path => {
              const refreshSection = getSectionByPath(sections, path);
              changeSectionLoadingState({ path, isGeneratingContent: true });
              const { isContentModified, isOutlineModified } = sectionModifiedState({ path });
              generateAdvancedWritingParagraph({
                sectionId: refreshSection?.sectionId,
                articleId,
                cleanupHistory: isOutlineModified, // 大纲编辑后重新生成节需要清除历史
                includeSubsection: false,
                enableRetrieval,
              })
                .catch(err => {
                  Message.error(locale.ad_writing_generate_section_error);
                })
                .finally(() => {
                  changeSectionLoadingState({ path, isGeneratingContent: false });
                  // 生成完毕后因为内容已经与编辑后的大纲一致，把节回复到没有手动编辑过的状态
                  removeSectionModifiedTag({ path, event: SectionModifiedEvent.SectionContentModified });
                  removeSectionModifiedTag({ path, event: SectionModifiedEvent.SectionOutlineModified });
                });
            }}
            onAddSubsSection={path => {
              const parentSection = getSectionByPath(sections, path);

              const sortNum = (parentSection?.subLevels?.length || 0) + 1;
              addSection({ articleId, parentSectionId: parentSection?.sectionId, sortNum: sortNum.toString() }, path);
            }}
            onAddSummary={path => {
              changeSectionLoadingState({ path, isGeneratingSummary: true });
              generateAdvancedWritingParagraph({
                articleId,
                sectionId: section.sectionId,
                includeSubsection: false,
                cleanupHistory: true,
                enableRetrieval,
              })
                .catch(err => {
                  Message.error(locale.ad_writing_generate_summary_error);
                })
                .finally(() => {
                  changeSectionLoadingState({ path, isGeneratingSummary: false });
                });
            }}
            onRemoveSummary={deleteSummary}
            onRefreshSummary={path => {
              changeSectionLoadingState({ path, isGeneratingSummary: true });
              generateAdvancedWritingParagraph({
                articleId,
                sectionId: section.sectionId,
                includeSubsection: false,
                cleanupHistory: false,
                enableRetrieval,
              })
                .catch(err => {
                  Message.error(locale.ad_writing_generate_summary_error);
                })
                .finally(() => {
                  changeSectionLoadingState({ path, isGeneratingSummary: false });
                });
            }}
            onTitleChange={(value, path) => updateSectionTitle({ value, path })}
          />
        </div>
      ))}
      <Button
        disabled={isGeneratingArticle || isGeneratingOutline}
        className="default text-xs"
        icon={<IconPlus />}
        onClick={async () => {
          const { result } = await addSection({ articleId, sortNum: (sections.length + 1).toString(), parentSectionId: '0' });
          // 新增章自动新增一节
          await addSection({ articleId, sortNum: '1', parentSectionId: result.sectionId }, [sections.length]);
        }}
      >
        {locale.ad_writing_add_section}
      </Button>
      <div className="h-6"></div>
    </div>
  );
};

export default Outline;
