import { Section } from '@hkgai/pb-fe-api/lib/interface/copilot_api';

export enum SectionModifiedEvent {
  SectionContentModified,
  SectionOutlineModified,
}

export interface NewSection extends Section {
  isContentModified?: boolean;
  isOutlineModified?: boolean;
  level?: number;
  selectIndex?: number;
  subLevels?: NewSection[];
  isGeneratingOutline?: boolean;
  isGeneratingContent?: boolean;
  isGeneratingSummary?: boolean;
}

export interface SectionLoadingState {
  isGeneratingOutline: boolean;
  isGeneratingContent: boolean;
  isGeneratingSummary: boolean;
  isSubSectionGeneratingContent: boolean;
  isSubSectionGeneratingOutline: boolean;
  isSubSectionGeneratingSummary: boolean;
}
