import { FC, useMemo } from 'react';
import { Button, Message, Modal, Spin } from '@arco-design/web-react';
import { useModel } from '@modern-js/runtime/model';
import { useNavigate } from '@modern-js/runtime/router';
import { useLocalStorageState } from 'ahooks';
import { QueryAiWritingResp_ArticleInfo } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import Outline from '../outline';
import AdvancedWritingStore from '@/store/advancedWritingStore';
import useLocale from '@/hooks/useLocale';
import { getSectionsContentStatus } from '@/store/advancedWritingStore/utils';
import { useArticleId } from '@/hooks/article';
import { createAndSaveAiWriting } from '@/api/copilot_api';
import GlobalStore from '@/store/globalStore';
import { SidebarStatus, SidebarType } from '@/type/copilot';
import { NewSection, SectionModifiedEvent } from '@/type/advanceWriting';

const Step3: FC<{
  onNextStep: () => void;
  onPreviousStep: () => void;
}> = ({ onNextStep, onPreviousStep }) => {
  const locale = useLocale();

  const userID = `copilot_${localStorage.getItem('USER_ID') ?? ''}`;
  const [aiList, setAilist] = useLocalStorageState<QueryAiWritingResp_ArticleInfo[]>(userID, {
    defaultValue: [],
    listenStorageChange: true,
  });

  const [
    { sections, writingParams, isGeneratingOutline, isGeneratingArticle, sectionModifiedState, articleInfo, enableRetrieval },
    { generateAdvancedWritingOutline, generateAdvancedWritingParagraph, changeSectionLoadingState, removeSectionModifiedTag, queryadvancedWritingInfo },
  ] = useModel(AdvancedWritingStore);

  const [{ sideBar }] = useModel(GlobalStore);

  const navigate = useNavigate();

  const articleId = useArticleId();

  const regenerateOutline = async () => {
    const { isContentModified, isOutlineModified } = sectionModifiedState({ path: [] });
    const regenerate = async () => {
      await generateAdvancedWritingOutline({ ...writingParams, articleId });
    };
    if (isContentModified || isOutlineModified) {
      Modal.confirm({
        title: locale.ad_writing_regenerate,
        content: locale.ad_writing_regenerate_outline_content,
        onConfirm: regenerate,
      });
    } else {
      regenerate();
    }
  };

  // 循环重新生成节
  const onRegenerateSubSectionsInSections = async (processingSections: NewSection[], globalCleanupHistory = false) => {
    for (const currentSection of processingSections) {
      // loading
      changeSectionLoadingState({ sectionId: currentSection?.sectionId, isGeneratingContent: true });
      if (currentSection.subLevels?.length) {
        // 递归生成节
        await onRegenerateSubSectionsInSections(currentSection.subLevels, globalCleanupHistory);
        // 如果有摘要，就重新生成摘要
        if (currentSection.sectionContents?.length) {
          changeSectionLoadingState({ sectionId: currentSection?.sectionId, isGeneratingSummary: true });
          try {
            currentSection.sectionId && document.getElementById(currentSection.sectionId)?.scrollIntoView({ behavior: 'smooth' });
            await generateAdvancedWritingParagraph({
              articleId,
              sectionId: currentSection.sectionId,
              includeSubsection: false,
              cleanupHistory: globalCleanupHistory,
              enableRetrieval,
            });
          } finally {
            changeSectionLoadingState({ sectionId: currentSection?.sectionId, isGeneratingSummary: false });
          }
        }
        // 生成章完毕后因为内容已经与编辑后的大纲一致，把章回复到没有手动编辑过的状态
        removeSectionModifiedTag({ sectionId: currentSection.sectionId, event: SectionModifiedEvent.SectionContentModified });
        removeSectionModifiedTag({ sectionId: currentSection.sectionId, event: SectionModifiedEvent.SectionOutlineModified });
      } else {
        // 重新生成节
        try {
          // 滚动到对应的节
          if (currentSection?.sectionId) {
            document.getElementById(currentSection.sectionId)?.scrollIntoView({ behavior: 'smooth' });
          }
          // 只清空修改大纲的节的历史
          const { isOutlineModified } = sectionModifiedState({ sectionId: currentSection.sectionId });
          await generateAdvancedWritingParagraph({
            sectionId: currentSection?.sectionId,
            articleId,
            cleanupHistory: globalCleanupHistory || isOutlineModified,
            includeSubsection: false,
            enableRetrieval,
          });
          // 生成完毕后因为内容已经与编辑后的大纲一致，把节回复到没有手动编辑过的状态
          removeSectionModifiedTag({ sectionId: currentSection.sectionId, event: SectionModifiedEvent.SectionContentModified });
          removeSectionModifiedTag({ sectionId: currentSection.sectionId, event: SectionModifiedEvent.SectionOutlineModified });
        } catch (err) {
          Message.error(locale.ad_writing_generate_section_error_with_title.replace('{sectionTitle}', currentSection?.sectionTitle || ''));
        }
      }
      changeSectionLoadingState({ sectionId: currentSection?.sectionId, isGeneratingContent: false });
    }
    // await generateAdvancedWritingArticle({ articleId, cleanupHistory, enableRetrieval });
  };

  const regenerateArticel = async () => {
    const { isContentModified, isOutlineModified } = sectionModifiedState({ path: [] });

    if (isOutlineModified) {
      Modal.confirm({
        title: locale.ad_writing_regenerate,
        content: locale.ad_writing_all_existing_content,
        onConfirm: () => {
          onRegenerateSubSectionsInSections(sections, isOutlineModified);
          return Promise.resolve();
        },
      });
    } else {
      onRegenerateSubSectionsInSections(sections, isOutlineModified);
    }
  };

  const { ids: unCompletedSections, textDocGroup } = useMemo(() => {
    return getSectionsContentStatus(sections);
  }, [sections]);

  return (
    <div className={`space-y-3 h-full flex flex-col ${isGeneratingOutline || isGeneratingArticle ? 'overflow-hidden' : 'overflow-auto'}`}>
      {isGeneratingOutline || isGeneratingArticle ? (
        <Spin loading={true} className="h-full flex-1">
          <div className="w-full h-full"></div>
        </Spin>
      ) : (
        <>
          <div className="flex gap-2">
            <Button type="outline" className="text-[10px] outline-button" onClick={regenerateOutline} disabled={isGeneratingArticle}>
              {locale.ad_writing_outline_regeneration}
            </Button>
            <Button type="outline" className="text-[10px] outline-button" onClick={regenerateArticel} loading={isGeneratingArticle}>
              {locale.ad_writing_full_text_generation}
            </Button>
          </div>
          <div className="flex-1 h-full">
            <Outline sections={sections} onRegenerateSubSectionsInSections={onRegenerateSubSectionsInSections} />
          </div>
        </>
      )}
      <div className="generate-outline-form-footer no-border flex justify-between">
        <Button
          onClick={() => {
            onPreviousStep();
          }}
        >
          {locale.ad_writing_back}
        </Button>
        <Button
          disabled={isGeneratingArticle || isGeneratingOutline || sections.length === 0}
          type="primary"
          className={`${unCompletedSections.length ? 'opacity-30' : ''}`}
          onClick={async () => {
            const persistenceConfig = sideBar?.find(item => item.type === (200 as SidebarType));
            const isPersistence = persistenceConfig ? persistenceConfig?.status === SidebarStatus.ENABLED : false;
            if (unCompletedSections.length) {
              const unCompleteElement = document.getElementById(unCompletedSections[0]);
              unCompleteElement?.scrollIntoView({ behavior: 'smooth' });
              Message.info(locale.ad_writing_complete_content);
              return;
            }
            const refreshedArticleInfo = await queryadvancedWritingInfo({ articleId });
            const savedTitle = refreshedArticleInfo.title || articleInfo.title;
            if (isPersistence) {
              // 保存到ai-writing
              const { articleId: aiWritingArticleId } = await createAndSaveAiWriting({
                title: savedTitle,
                content: JSON.stringify(textDocGroup),
              });
              navigate(`/writing?aid=${aiWritingArticleId}`);
              onNextStep();
            } else {
              const nowDate = Date.now();
              const articleInfoTemp = {
                articleId: nowDate.toString(),
                createTimeSec: (nowDate / 1000).toFixed(), // 使用当前时间戳作为创建时间
                updateTimeSec: (nowDate / 1000).toFixed(), // 使用当前时间戳作为创建时间
                title: savedTitle,
                content: JSON.stringify(textDocGroup),
              };
              setAilist([articleInfoTemp, ...(aiList || [])]);
              navigate(`/writing?aid=${nowDate}`);
              onNextStep();
            }
          }}
        >
          {locale.ad_writing_insert}
        </Button>
      </div>
    </div>
  );
};

export default Step3;
