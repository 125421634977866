import { Steps, Switch } from '@arco-design/web-react';
import './index.scss';
import { useMemo } from 'react';
import { useModel } from '@modern-js/runtime/model';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import AdvancedWritingStore from '@/store/advancedWritingStore';
import useLocale from '@/hooks/useLocale';

const Form = () => {
  const locale = useLocale();

  const [{ currentStep, enableRetrieval }, { setCurrentStep, setEnableRetrieval }] = useModel(AdvancedWritingStore);

  const gotoNextStep = () => {
    setCurrentStep(currentStep + 1 <= 3 ? currentStep + 1 : currentStep);
  };
  const gotoPreviousStep = () => {
    setCurrentStep(currentStep - 1 >= 0 ? currentStep - 1 : currentStep);
  };

  const steps = useMemo(
    () => [
      <Steps.Step key={1} title={locale.ad_writing_general} status="wait" />,
      <Steps.Step key={2} title={locale.ad_writing_reference} status="wait" />,
      <Steps.Step key={3} title={locale.ad_writing_outline} status="wait" />,
    ],
    [locale],
  );

  const renderStepForm = (step: number) => {
    switch (step) {
      case 1:
        return (
          <Step1
            onNextStep={async () => {
              gotoNextStep();
            }}
          />
        );
      case 2:
        return <Step2 onPreviousStep={gotoPreviousStep} onNextStep={gotoNextStep} />;
      case 3:
        return <Step3 onPreviousStep={gotoPreviousStep} onNextStep={gotoNextStep} />;
      default:
        return null;
    }
  };
  return (
    <div className="generate-outline-form space-y-4">
      <div className="bg-[#F7F8FA] h-10 px-3 font-medium text-sm leading-10">
        <div className="flex items-center justify-between">
          <div>{locale.ad_writing}</div>
          <div className="flex items-center gap-1">
            <Switch checked={enableRetrieval} onChange={checked => setEnableRetrieval(checked)} />
            <span className="font-normal">{locale.ad_writing_online_search}</span>
          </div>
        </div>
      </div>
      <div className="px-4">
        <Steps current={currentStep}>{steps}</Steps>
      </div>
      <div className="px-4 h-full flex-1 mb-9 overflow-auto">{renderStepForm(currentStep)}</div>
    </div>
  );
};

export default Form;
