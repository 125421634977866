import { useLocation, useNavigate, useSearchParams } from '@modern-js/runtime/router';

export const useArticleId = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return searchParams.get('aid') || '';
};

export const useNavigateByArticleId = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (articleId?: string) => {
    if (articleId) {
      navigate(`${location.pathname}?aid=${articleId}`);
    }
  };
};
