import { model } from '@modern-js/runtime/model';
import { QueryAiWritingResp_ArticleInfo, QueryOutlineWritingResp_ArticleInfo } from '@hkgai/pb-fe-api/lib/interface/copilot_api';

export enum SiderType {
  AIWRITING = 'AIWRITING',
  ADVANCED = 'ADVANCED',
}
interface Props {
  advanceList: QueryOutlineWritingResp_ArticleInfo[];
  adTotalCount: number;
  adCurrentIndex: number;
  advanceReqComplete: boolean;

  aiWritingList: QueryAiWritingResp_ArticleInfo[];
  currentIndex: number;
  totalCount: number;
  aiWritingReqComplete: boolean;

  siderTypeStore: SiderType;
}

const AiWritingStore = model<Props>('AiWritingStore').define({
  state: {
    advanceList: [],
    adCurrentIndex: 1,
    adTotalCount: 0,
    advanceReqComplete: false,

    aiWritingList: [], // AI写作列表
    currentIndex: 1,
    totalCount: 0,
    aiWritingReqComplete: false,

    siderTypeStore: SiderType.AIWRITING,
  },
  actions: {
    // 设置url当前的siderType
    setSiderType(draft, data) {
      draft.siderTypeStore = data;
    },
    // 设置AI写作列表
    setAiWritingList(draft, data: QueryAiWritingResp_ArticleInfo[]) {
      draft.aiWritingList = data;
    },
    // 设置AI写作选中当前页
    setCurrentIndex(draft, data) {
      draft.currentIndex = data;
    },
    // 设置AI写作总条数
    setTotalCount(draft, data) {
      draft.totalCount = data;
    },
    // 设置AI写作列表是否请求完成
    setAiWritingReqComplete(draft, data) {
      draft.aiWritingReqComplete = data;
    },
    // 设置长文写作列表
    setAdvanceList(draft, data: QueryOutlineWritingResp_ArticleInfo[]) {
      draft.advanceList = data;
    },
    // 设置长文写作选中当前页
    setAdCurrentIndex(draft, data) {
      draft.adCurrentIndex = data;
    },
    // 设置长文写作总条数
    setAdTotalCount(draft, data) {
      draft.adTotalCount = data;
    },
    // 设置长文紫萼做列表是否请求完成
    setAdvanceReqComplete(draft, data) {
      draft.advanceReqComplete = data;
    },
  },
  effects: {},
});

export default AiWritingStore;
