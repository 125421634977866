import { Checkbox, Input, Message } from '@arco-design/web-react';
import { FC, useEffect, useState } from 'react';
import { Descendant } from 'slate';
import './index.scss';
import useLocale from '@/hooks/useLocale';

type KeywordsSelectProps = {
  options?: string[];
  value?: string[];
  onChange?: (value: string[], options: string[]) => void;
};

function isDiffArray(arr1: string[], arr2: string[]) {
  if (arr1.length !== arr2.length) {
    return true;
  }
  if ([...new Set([...arr1, ...arr2])].length !== arr1.length) {
    return true;
  }
  return false;
}

const OptionNodeType = 'option';
const editorInitialValue: Descendant[] = [];
const KeywordOption: FC<{ value?: string; label?: string; checked?: boolean; onClick: (checked: boolean) => void }> = ({ value = '', label = '', checked = false, onClick }) => {
  return (
    <div className={`select-item ${checked ? 'checked' : ''}`} onClick={() => onClick(checked)}>
      <Checkbox checked={checked} value={value} />
      <div className="text-ellipsis overflow-hidden max-w-full">{label}</div>
    </div>
  );
};

const KeywordsSelect: FC<KeywordsSelectProps> = ({ options: propsOptions, value: propsValue, onChange }) => {
  const [options, setOptions] = useState<string[]>([]);
  const [value, setValue] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const locale = useLocale();

  useEffect(() => {
    if (propsValue && isDiffArray(value, propsValue)) {
      setValue(propsValue);
    }
  }, [propsValue, value]);
  useEffect(() => {
    if (propsOptions && isDiffArray(options, propsOptions)) {
      setOptions(propsOptions);
    }
  }, [propsOptions, options]);

  const addValue = (val: string) => {
    setValue(pre => [...new Set([...pre, val])]);
    onChange?.([...new Set([...value, val])], [...new Set([...options, val])]);
  };
  const removeValue = (val: string) => {
    setValue(pre => pre.filter(e => e !== val));
    const newOptions = [...options, val];
    setOptions(newOptions);
    onChange?.(
      value.filter(e => e !== val),
      newOptions,
    );
  };

  const addOption = (option: string) => {
    setOptions(pre => [...new Set([...pre, option])]);
    onChange?.(value, [...new Set([...options, option])]);
  };
  const removeOption = (option: string) => {
    setValue(pre => pre.filter(e => e !== option));
  };

  return (
    <div className="keywords-select">
      <span className="flex items-center w-fit gap-2 max-w-full flex-wrap">
        {[...new Set([...value, ...options])].map(option => (
          <KeywordOption
            key={option}
            label={option}
            value={option}
            checked={value.includes(option)}
            onClick={checked => {
              if (checked) {
                removeValue(option);
              } else {
                addValue(option);
              }
            }}
          />
        ))}
      </span>
      <Input.TextArea
        value={inputValue}
        onChange={setInputValue}
        onKeyDown={e => {
          const inputValue = e.currentTarget.value.trim(); // .replaceAll(/\s+/, '');

          if (!e.ctrlKey && e.key.toLocaleLowerCase() === 'enter' && inputValue.length) {
            e.preventDefault();
            if (inputValue.length > 10) {
              Message.error(locale.ad_writing_keyword_limit.replace(/{limit}/, 10));
              return;
            }
            addOption(inputValue);
            addValue(inputValue);
            setInputValue('');
          }
        }}
        placeholder={locale.ad_writing_keywords_placeholder}
      />
    </div>
  );
};

export default KeywordsSelect;
